/*@include media-breakpoint-up(md) {
    .gallery-deck {
        flex-flow: row wrap;
    }
    .card {
        margin-bottom: 0 !important;
    }
}
*/

.gallery-deck {
    display: flex;
    flex-direction: column;

    .card {
        margin-bottom: 0;
    }

    @media (min-width: 576px) {
        flex-flow: row wrap;
        margin-right: -1em;
        margin-left: -1em;

        .card {
            display: flex;
            // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
            flex: 1 0 0%;
            flex-direction: column;
            margin-right: 1em;
            margin-bottom: 0; // Override the default
            margin-left: 1em;
        }
    }
}